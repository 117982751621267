<template>
    <div>
        창장물
    </div>
</template>
<script>

export default {
    components: {
    },
}
</script>
